<template>
  <div>
    <b-container class="m-0 w-100 pt-2" fluid>
      <b-card>
        <b-row>
          <b-card-title class="mr-2"> FOLLOW-UP: </b-card-title>
          <div>
            <feather-icon
              icon="SquareIcon"
              style="margin-right: 0.5rem; fill: #ff9f43"
              class="text-warning"
            />
            DAYS
          </div>
          <div class="ml-2">
            <feather-icon
              class="cursor-pointer"
              :class="isBusy ? 'lds-ring' : ''"
              icon="RefreshCcwIcon"
              size="18"
              @click="getLevelClaim"
            />
          </div>
        </b-row>
        <b-row>
          <b-col
            lg="4"
            md="4"
            class="my-2"
            v-for="(level, index) in levels"
            :key="index"
          >
            <feather-icon
              icon="SquareIcon"
              style="fill: #ff9f43"
              class="text-warning mr-1"
            />
            <span style="font-weight: bold">
              DAYS TO FOLLOW UP CLAIMS IN
              {{ level.level_name.toUpperCase() }}
            </span>
            <b-input-group class="mt-2">
              <b-input-group-prepend>
                <b-button variant="outline-secondary" class="btn-icon">
                  <tabler-icon icon="AlarmIcon" class="text-primary" />
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                type="number"
                v-model="level.follow_up_days"
                class="form-control input-form"
                :disabled="!level.editing"
              />
              <b-input-group-append>
                <b-button
                  v-if="!level.editing"
                  variant="outline-primary"
                  class="btn-icon"
                  @click="trackingModal(level)"
                  v-b-tooltip.hover.top="'Tracking'"
                  :disabled="isDisabled"
                >
                  <feather-icon icon="ListIcon" class="cursor-pointer" />
                </b-button>
                <b-button
                  v-if="!level.editing"
                  variant="outline-warning"
                  class="btn-icon"
                  @click="activeInput(level)"
                  v-b-tooltip.hover.top="'Edit'"
                  :disabled="isDisabled"
                >
                  <feather-icon icon="Edit2Icon" class="cursor-pointer" />
                </b-button>
                <b-button
                  v-if="level.editing"
                  variant="outline-primary"
                  class="btn-icon"
                  @click="changeDays(level)"
                  v-b-tooltip.hover.top="'Save'"
                >
                  <feather-icon icon="SaveIcon" class="cursor-pointer" />
                </b-button>
                <b-button
                  v-if="level.editing"
                  variant="outline-danger"
                  class="btn-icon"
                  @click="disableInput(level)"
                  v-b-tooltip.hover.top="'Cancel'"
                >
                  <feather-icon icon="XIcon" class="cursor-pointer" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
    <TrackingModal
      v-if="showTrackingModal"
      :itemId="itemId"
      :itemType="itemType"
      @close="closeTrackingModal"
    >
    </TrackingModal>
  </div>
</template>
<script>
import SettingsService from "@/views/quality/views/settings/service/settings.service.js";
import TrackingModal from "./modal/TrackingModal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    TrackingModal,
  },
  data() {
    return {
      levels: [],
      showModal: false,
      levelSelected: [],
      isBusy: false,
      vMoney: {
        decimal: ".",
        thousand: ",",
        precision: 0,
        min: 0,
        maxlength: 2,
      },
      showTrackingModal: false,
      itemType: "follow days",
      itemId: null,
      auxLevels: [],
    };
  },
  computed: {
    isDisabled: function () {
      return ![1, 2, 17].includes(this.currentUser.role_id);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.getLevelClaim();
  },
  methods: {
    activeInput(level) {
      level.editing = true;
    },
    async disableInput(level) {
      await this.getLevelClaim();
      level.editing = false;
    },
    async getLevelClaim() {
      const dataToEdit = {
        editing: false,
        value: null,
        savedData: null,
      };
      this.isBusy = true;
      await new Promise((resolve) => setTimeout(resolve, 200));
      const levels = await SettingsService.getLevelClaim();
      this.auxLevels = levels;
      this.levels = [];
      levels.map((level, i) =>
        this.levels.push({ ...levels[i], ...dataToEdit })
      );
      this.isBusy = false;
    },
    async changeDays(level) {


      if ( level.follow_up_days > 0 ){
        const formdata = {
        id: level.id,
        follow_up_days: level.follow_up_days,
        old_follow_up_days: this.auxLevels.find(
          (auxLevel) => auxLevel.id == level.id
        ).follow_up_days,
        userId: this.currentUser.user_id,
        type: "follow days",
      };
    
      const confirm = await this.showConfirmSwal(
        `Are you sure to change the day?`
      );

      if (confirm.isConfirmed) {
        try {
          let data = await SettingsService.updateFollowDays(formdata);
          if (data.success) {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
            /* level.showEditDayIcon = false;
            level.disabledInput = true;
            level.dayBackup = null; */
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            );
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
      this.disableInput(level);
      } else {
        this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Invalid number"
            );
      }
    },
    trackingModal(level) {
      this.itemId = level.id;
      this.showTrackingModal = true;
    },
    closeTrackingModal() {
      this.showTrackingModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.lds-ring {
  animation: lds-ring 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>